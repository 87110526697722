import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import React from 'react'
import { SectionWrapper } from './Section'
import { ITestimonial } from 'types'

function Quote({ children, className = '' }: { children: React.ReactNode[]; className?: string }) {
    return (
        <div
            className={`-mx-5 grid gap-y-8 bg-gray-accent-light py-10 px-5 md:-mx-12 md:grid-cols-2 md:gap-y-0 md:gap-x-8 md:px-16 lg:gap-x-12 xl:gap-x-16 ${className}`}
        >
            <div>{children[0]}</div>
            <div className="-mt-16">{children[1]}</div>
        </div>
    )
}

export default function Testimonial({ googleImages, marketTestimonial, marketTestimonial2 }: ITestimonial) {
    if (Array.isArray(marketTestimonial) && marketTestimonial.length == 1) marketTestimonial = marketTestimonial[0]
    const author = marketTestimonial?.author
    const image = marketTestimonial?.image
    const quote = marketTestimonial?.quote
    const svgImage = marketTestimonial?.svgImage
    const imageFile = marketTestimonial?.imageFile
    const googleImage =
        image &&
        googleImages &&
        googleImages.filter((img) => {
            if (img.name == image) {
                return img
            }
        })[0]
    const gatsbImage = (googleImage && getImage(googleImage)) || (imageFile && getImage(imageFile))
    const authorCompanyName = author?.company?.name || author?.companyName
    const googleAuthorCompanyImage =
        author?.companyImage &&
        googleImages &&
        googleImages.filter((img) => {
            if (img.name == author?.companyImage) {
                return img
            }
        })[0]?.publicURL
    const authorCompanyImage = googleAuthorCompanyImage || author?.company?.image
    // const gatsbAuthorCompanyImage =  googleAuthorCompanyImage && getImage(googleAuthorCompanyImage)
    const googleAuthorImage =
        author?.image &&
        googleImages &&
        googleImages.filter((img) => {
            if (img.name == author?.image) {
                return img
            }
        })[0]?.publicURL
    const authorImage = googleAuthorImage || author?.image
    // const gatsbAuthorImage =  googleAuthorImage && getImage(googleAuthorImage)
    // console.log(
    //     `marketTestimonial ${JSON.stringify(marketTestimonial)} author?.imag ${
    //         author?.imag
    //     } googleAuthorCompanyImage ${googleAuthorCompanyImage} googleAuthorImage ${googleAuthorImage}`
    // )
    return (
        <SectionWrapper className="relative mt-0 !pt-0">
            <Quote className="items-end">
                <div>
                    {authorCompanyImage && <img className=" max-h-[45px]" src={authorCompanyImage} />}
                    {quote && <p className="my-6 text-[17px]" dangerouslySetInnerHTML={{ __html: quote }} />}

                    <div className="flex items-center space-x-4">
                        {authorImage && <img className="max-w-[50px] rounded-full" src={authorImage} />}
                        <div>
                            {author?.name && <p className="m-0 font-bold">{author?.name}</p>}
                            {author?.role && authorCompanyName && (
                                <p className="m-0 opacity-70">
                                    {author?.role}, {authorCompanyName}
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    {gatsbImage && <GatsbyImage alt="" image={gatsbImage} />}
                    {svgImage && !gatsbImage && <img alt="" src={svgImage} />}
                </div>
            </Quote>
        </SectionWrapper>
    )
}
