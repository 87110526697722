import React from 'react'
import { IBlogPosts } from './types'
import { Post } from '../Blog'
import { SectionWrapper } from './Section'

export default function BlogPosts({ posts, title }: IBlogPosts) {
    return (
        posts &&
        posts?.length > 0 && (
            <SectionWrapper>
                <div className="mx-auto max-w-2xl">
                    <h3>{title}</h3>
                    <ul className="m-0 list-none space-y-4 p-0">
                        {posts?.map((post: any) => {
                            const {
                                node: {
                                    id,
                                    frontmatter: { date, title, featuredImage, authors, category },
                                    fields: { slug },
                                    parent,
                                },
                            } = post
                            const featuredImg = featuredImage || parent?.cover?.image

                            return (
                                <li
                                    className="relative shadow-lg after:absolute after:-inset-1.5 after:rounded-md after:border-0 after:border-black/25 hover:top-[-.5px] hover:scale-[1.01] hover:after:border-1 active:top-[.5px] active:scale-[.99]"
                                    key={id}
                                >
                                    <Post
                                        date={date}
                                        title={title}
                                        featuredImage={featuredImg}
                                        authors={authors}
                                        category={category}
                                        slug={slug}
                                        imgClassName="w-full"
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </SectionWrapper>
        )
    )
}
