import { CallToAction } from 'components/CallToAction'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import React from 'react'

export default function HeroCondensed({
    title,
    subtitle,
    image,
    mainCTA,
    pricingCTA,
    sticky = true,
}: {
    sticky?: boolean
    title: string
    subtitle: string
    image: {
        image: ImageDataLike
        width: number | string
        height: number | string
    }
    mainCTA: {
        title: string
        url: string
    }
    pricingCTA: {
        title: string
        url: string
    }
}) {
    const gatsbyImage = image?.image && getImage(image?.image)
    const imageStyles = { maxWidth: image?.width || '56rem', maxHeight: image?.height || 'auto' }
    return (
        <div
            className={`-mx-5 mb-8 bg-gray-accent/30 px-5 pt-3 pb-3 backdrop-blur sm:-mt-4 lg:-mx-6 lg:-mt-12 lg:px-6 xl:-mx-12 xl:-mt-12 xl:px-12 ${
                sticky ? 'sticky top-0 z-20 bg-tan py-2' : ''
            }`}
        >
            <div className="flex w-full flex-col gap-4 sm:flex-row sm:items-center sm:gap-8">
                <div className="flex-1">
                    <h1 id="overview" className="mt-2 mb-0 text-lg text-primary md:my-0">
                        {title} by iCloudReady
                    </h1>
                    {/* <p className="font-medium  m-0 mt-1 p-0" dangerouslySetInnerHTML={{ __html: subtitle }} /> */}
                </div>

                <div className="flex flex-[0_0_auto] items-center space-x-4">
                    {mainCTA && (
                        <CallToAction to={mainCTA.url} size="sm" className="">
                            {mainCTA.title}
                        </CallToAction>
                    )}
                    {pricingCTA && (
                        <CallToAction type="secondary" to={pricingCTA.url} size="sm" className="">
                            {pricingCTA.title}
                        </CallToAction>
                    )}
                </div>
            </div>
        </div>
    )
}
