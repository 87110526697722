import React from 'react'
import Link from 'components/Link'
import { SectionWrapper } from './Section'
import { IFeature } from './types'
import PairGrid from './PairGrid'

export default function PairsWith({ markets }: { markets: IFeature[] }) {
    return (
        <div id="pairs-with">
            <SectionWrapper className="max-w-full">
                <h2 className="mb-2 text-center text-4xl">Explore other portfolios...</h2>
                <p className="mb-0 text-center text-lg  font-semibold">
                    iCloudReady is fully customizable for any mixed-use portfolio.
                </p>
                <PairGrid features={markets} />
            </SectionWrapper>
        </div>
    )
}
