import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { IComparison } from './types'

export default function Comparison({ description, children }: IComparison) {
    return (
        <div id="comparison" className="mx-auto max-w-5xl">
            <div className="flex items-end justify-between">
                <div>
                    <h2 className="m-0">iCloudReady vs...</h2>
                    <p className="m-0">{description}</p>
                </div>
                <div>
                    <StaticImage className="max-w-[530px]" alt="iCloudReady vs..." src="./images/vs.png" />
                </div>
            </div>
            <div className="article-content mt-12 overflow-x-auto">{children}</div>
        </div>
    )
}
