import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { IMarquee, ISection, ISectionHeading, ISectionWrapper } from './types'
import { FeatureList } from './Feature'
import TwoCol from './TwoCol'
import slugify from 'slugify'
import { CallToAction } from '../CallToAction'
import { LightBulbIcon } from '@heroicons/react/outline'
import GatsbyLink from '../Link'
import MarqueeContainer from 'react-fast-marquee'
import { NodeCollapseOutlined } from '@ant-design/icons'
export const SectionHeading = ({ title, subtitle }: ISectionHeading) => {
    return (
        <div className="mb-6">
            {title && (
                <h2 className="m-0 -mt-4 pt-4 text-3xl" id={slugify(title?.replace('&-', ''), { lower: true })}>
                    {title}
                </h2>
            )}
            {subtitle && typeof subtitle === 'string' ? (
                <p className="m-0 text-base font-semibold opacity-70">{subtitle}</p>
            ) : (
                subtitle
            )}
        </div>
    )
}
const Marquee = ({ slides, googleImages }: { slides: IMarquee[] }) => {
    return (
        <div className="absolute left-0 w-full bg-gray-accent-light py-4">
            <MarqueeContainer autoFill pauseOnHover>
                {slides.map(({ text, url }, index) => {
                    const Container = url ? GatsbyLink : 'div'
                    return (
                        <Container {...(url ? { to: url } : null)} key={text + index} className="mx-2">
                            "{text}"
                        </Container>
                    )
                })}
            </MarqueeContainer>
        </div>
    )
}

export const SectionWrapper = ({ children, className = '' }: ISectionWrapper) => {
    return (
        <section className={`my-1 list-none py-4 md:py-12 ${className}`}>
            <div className={`mx-auto max-w-7xl`}>{children}</div>
        </section>
    )
}

export const Sections = ({ sections, googleImages }: { sections: ISection[][] }) => {
    return (
        <>
            {sections?.map((section, sectionIndex) => {
                // console.log(`section Not Transformed ${JSON.stringify(section)}`)
                return (
                    <SectionWrapper className="!my-0 py-12 md:my-auto md:py-14" key={sectionIndex}>
                        {section?.length > 1 ? (
                            <TwoCol>
                                {section?.map((sectionItem, index) => {
                                    let transformedSections = sectionItem
                                    if (Array.isArray(transformedSections) && transformedSections?.length == 1) {
                                        transformedSections = sectionItem[0]
                                    }
                                    // let transformedEmbeddedSections = sectionItem.sections
                                    // if (Array.isArray(transformedEmbeddedSections) && transformedEmbeddedSections?.length == 1){
                                    //     transformedEmbeddedSections = sectionItem.sections[0]
                                    //     transformedSections[sections] =transformedEmbeddedSections
                                    // }
                                    // console.log(`transformedSections ${JSON.stringify(transformedSections)}`)
                                    return (
                                        <Section
                                            align={transformedSections?.align}
                                            googleImages={googleImages}
                                            key={index}
                                            {...transformedSections}
                                            callout={transformedSections?.callout}
                                            className={sectionIndex % 2 && index % 2 ? 'md:-order-1' : ''}
                                        />
                                    )
                                })}
                            </TwoCol>
                        ) : section[0][0]?.marquee ? (
                            <Marquee slides={section[0][0].marquee} googleImages={googleImages} />
                        ) : (
                            <Section {...section[0][0]} googleImages={googleImages} />
                        )}
                    </SectionWrapper>
                )
                // })
            })}
        </>
    )
}

export default function Section({
    title,
    key,
    subtitle,
    features,
    image,
    imageFile,
    svgImage,
    content,
    align,
    sections,
    googleImages,
    imageFrame = true,
    imageMaxWidth,
    imageMaxHeight,
    className = '',
    callout,
}: ISection) {
    const googleImage =
        image &&
        googleImages &&
        googleImages.filter((img) => {
            if (img.name == image) {
                return img
            }
        })[0]
    // console.log(`received sectionItemsPropos at index ${key} title ${title} subtitle ${subtitle}  align ${align}  features ${JSON.stringify(features)} content ${content} sections ${sections} image ${image} googleImage ${googleImage} googleImages ${JSON.stringify(googleImages)} imageFrame ${imageFrame}`)
    const imageWidth = imageMaxWidth || '512px'
    const imageHeight = imageMaxHeight || '60px'
    const gatsbImage = (googleImage && getImage(googleImage)) || (imageFile && getImage(imageFile))
    let transformedSection = sections
    let transformedCallout = callout
    if (Array.isArray(transformedCallout) && transformedCallout?.length == 1) {
        transformedCallout = callout[0]
    }
    if (Array.isArray(transformedSection) && transformedSection?.length == 1) {
        transformedSection = sections[0]
    }
    let transformedCalloutCta = transformedCallout?.cta

    if (Array.isArray(transformedCalloutCta) && transformedCalloutCta?.length == 1) {
        transformedCalloutCta = transformedCalloutCta[0]
    }
    // console.log(`transformedCalloutCta ${JSON.stringify(transformedCalloutCta)}`)
    return (
        <div className={className} style={{ alignSelf: align }}>
            {(title || subtitle) && <SectionHeading title={title} subtitle={subtitle} />}
            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
            {features && features?.length > 0 && <FeatureList features={features} />}
            {gatsbImage && (
                <GatsbyImage
                    alt={title || ''}
                    image={gatsbImage}
                    className={imageFrame ? 'overflow-hidden rounded shadow-xl' : ''}
                />
            )}
            {svgImage && !gatsbImage && (
                <img
                    alt={title || ''}
                    className={imageFrame ? 'overflow-hidden rounded shadow-xl' : ''}
                    src={svgImage}
                />
            )}

            {transformedSection &&
                (transformedSection?.length > 1 ? (
                    <TwoCol>
                        {transformedSection?.map((section, index) => {
                            // console.log(`section2 ${JSON.stringify(section)}`)
                            return (
                                <Section align={section?.align} key={index} googleImages={googleImages} {...section} />
                            )
                        })}
                    </TwoCol>
                ) : (
                    <Section {...transformedSection[0]} googleImages={googleImages} />
                ))}
            {transformedCallout && transformedCalloutCta && (
                <div className="mt-4 inline-block rounded-md border border-dashed border-gray-accent-light p-5">
                    <div className="flex items-start space-x-2">
                        <LightBulbIcon className="w-6 flex-shrink-0" />
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: transformedCallout?.content }} />
                            <CallToAction size="sm" type="secondary" to={transformedCalloutCta?.url}>
                                {transformedCalloutCta?.label}
                            </CallToAction>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
