import { CallToAction } from 'components/CallToAction'
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image'
import React from 'react'

export default function Hero({
    allHero,
    googleImages,
    title,
    subtitle,
    heroDescription,
    image,
    svgImage,
    mainCTA,
    pricingCTA,
}: {
    title: string
    subtitle: string
    heroDescription: string
    image: {
        image: ImageDataLike
        width: number | string
        height: number | string
    }
    svgImage: string
    mainCTA: {
        title: string
        url: string
    }
    pricingCTA: {
        title: string
        url: string
    }
}) {
    if (Array.isArray(allHero) && allHero.length == 1) allHero = allHero[0]
    const heroTitle = allHero?.title || title
    const heroSubtitle = allHero?.subtitle || subtitle
    const heroMainCTA = allHero?.mainCTA || mainCTA
    const heroPricingCTA = allHero?.pricingCTA || pricingCTA
    const heroImageName = allHero?.image
    const googleImage =
        heroImageName &&
        googleImages &&
        googleImages.filter((img) => {
            if (img.name == heroImageName) {
                return img
            }
        })[0]
    const heroImage = googleImage || image?.image
    const gatsbyImage = heroImage && getImage(heroImage)
    // console.log(`allHero ${JSON.stringify(allHero)} gatsbyImage ${JSON.stringify(image)}`)
    const svgSource = svgImage
    const imageStyles = { maxWidth: image?.width || '56rem', maxHeight: image?.height || 'auto' }
    return (
        <>
            <div>
                <h1 id="overview" className="mb-0 mt-8 text-center text-5xl md:mt-14 lg:text-6xl 2xl:text-7xl">
                    <span className="text-red">{heroTitle}</span>
                    {/* {' '}<span className="inline-block">iCloudReady does that.</span> */}
                </h1>
                <p
                    className="mt-4 text-center text-lg  font-semibold"
                    dangerouslySetInnerHTML={{ __html: heroSubtitle }}
                />
                {heroPricingCTA && heroMainCTA && (
                    <div className="flex flex-col justify-center space-y-3 md:flex-row md:items-center md:space-y-0 md:space-x-4">
                        <CallToAction to={heroMainCTA.url} size="sm" className="md:min-w-[200px]">
                            {heroMainCTA.title}
                        </CallToAction>
                        <CallToAction type="secondary" to={heroPricingCTA.url} size="sm" className="md:min-w-[200px]">
                            {heroPricingCTA.title}
                        </CallToAction>
                    </div>
                )}
            </div>
            {gatsbyImage && (
                //         <div
                //         className="grid grid-cols-1 md:grid-cols-3 space-y-6 md:space-y-0 md:space-x-16 items-center py-8 border-b border-gray-accent-light border-dashed last:border-b-0 max-w-5xl mx-auto px-5 box-content"
                //     >
                // {heroDescription && <p className="my-0 mt-1 text-lg  font-semibold">{heroDescription}</p>}
                <div
                    style={imageStyles}
                    //col-span-2
                    className="leading-0 relative mx-auto mt-8 -mb-12 text-center after:absolute after:bottom-12 after:left-0 after:h-36 after:w-full after:bg-gradient-to-b after:from-tan/0 after:via-tan/60 after:to-tan/100 after:content-['']"
                >
                    <GatsbyImage alt={title} image={gatsbyImage} className="rounded-md shadow-xl" />
                </div>

                // </div>
            )}
            {svgImage && !gatsbyImage && (
                <div
                    style={imageStyles}
                    //col-span-2
                    className="leading-0 relative mx-auto mt-8 -mb-12 text-center after:absolute after:bottom-12 after:left-0 after:h-36 after:w-full after:bg-gradient-to-b after:from-tan/0 after:via-tan/60 after:to-tan/100 after:content-['']"
                >
                    <img alt={title} className="rounded-md shadow-xl" src={svgSource} />
                </div>
            )}
        </>
    )
}
