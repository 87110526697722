import React from 'react'
import { IFeature, IFeatureGridProps } from './types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as MarketIcons from 'components/ProductIcons'
import * as NotMarketIcons from 'components/NotProductIcons'
import { SectionWrapper } from './Section'
import { getTailwindGridCol } from './util'

export const MainFeatures = (props: IFeatureGridProps) => {
    return (
        <ul className="p-0" id="features">
            <FeatureGrid {...props} />
        </ul>
    )
}

export const FeatureList = ({ features }: { features: IFeature[] }) => {
    //  if (Array.isArray(FeatureList) {}
    //   if (Array.isArray(features) && features.length == 1) features = features[0]
    return (
        <ul className="m-0 list-none space-y-6 p-0">
            {features &&
                Array.isArray(features) &&
                features.map(({ title, description }) => {
                    return (
                        <li key={title}>
                            <FeatureTitle>{title}</FeatureTitle>
                            <FeatureDescription>{description}</FeatureDescription>
                        </li>
                    )
                })}
        </ul>
    )
}

export const FeatureGrid = ({ features, className = '', googleImages }: IFeatureGridProps) => {
    const length = features?.length ?? 1
    return (
        <SectionWrapper className="max-w-full">
            <ul
                className={`max-w-screen-4xl m-0 mx-auto grid list-none space-y-8 p-0 md:space-y-0 ${getTailwindGridCol(
                    length
                )} ${className}`}
            >
                {features?.map((feature) => {
                    return <Feature key={feature.title} googleImages={googleImages} feature={feature} />
                })}
            </ul>
        </SectionWrapper>
    )
}

export const FeatureTitle = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => (
    <h3 className={`mb-1 text-[17px] leading-tight ${className}`}>{children}</h3>
)

export const FeatureDescription = ({ children, className = '' }: { children: React.ReactNode; className?: string }) => (
    <p className={`m-0 text-[15px] ${className}`}>{children}</p>
)

export const Feature = ({ feature, googleImages }: IFeature) => {
    const { title, description, className = '', icon, image } = feature
    const googleImage =
        image &&
        googleImages &&
        googleImages.filter((img) => {
            if (img.name == image) {
                return img
            }
        })[0]
    const gatsbImage = googleImage && getImage(googleImage)
    const Icon = MarketIcons[icon] || NotMarketIcons[icon]
    return (
        <li className={`sm:p-6 sm:pb-8  ${className}`}>
            {Icon && <Icon className="mb-4 h-10 w-10 opacity-50" />}
            {gatsbImage && <GatsbyImage alt={title || ''} image={gatsbImage} className="mb-4 h-10 w-10 opacity-50" />}
            <FeatureTitle>{title}</FeatureTitle>
            <FeatureDescription>{description}</FeatureDescription>
        </li>
    )
}
