import { CallToAction } from 'components/CallToAction'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { SectionWrapper } from './Section'
import { ICTA } from './types'

export default function CTA({ title, subtitle, image, svgImage, allCTA, mainCTA, pricingCTA, googleImages }: ICTA) {
    if (Array.isArray(allCTA) && allCTA.length == 1) allCTA = allCTA[0]
    const ctaTitle = allCTA?.title || title
    const ctaSubtitle = allCTA?.subtitle || subtitle
    const ctaMainCTATitle = allCTA?.title || mainCTA?.title
    const ctaMainCTAUrl = allCTA?.url || mainCTA?.url
    const ctaPricingCTA = allCTA?.pricingCTA || pricingCTA
    const ctaImageName = allCTA?.image
    const googleImage =
        ctaImageName &&
        googleImages &&
        googleImages.filter((img) => {
            if (img.name == ctaImageName) {
                return img
            }
        })[0]
    const ctaImage = googleImage || image
    const gatsbyImage = ctaImage && getImage(ctaImage)
    // console.log(`ctaImageName ${ctaImageName} googleImage ${googleImage} gatsbyImage ${JSON.stringify(gatsbyImage)} `)
    return (
        <SectionWrapper>
            <div className="flex flex-col justify-between space-y-4 rounded-lg bg-gray-accent-light p-12 md:flex-row md:items-center md:space-y-0 md:space-x-4">
                <div>
                    <h2 className="m-0">{ctaTitle}</h2>
                    <p className="m-0 mb-6">{ctaSubtitle}</p>
                    <div className="flex items-center space-x-2">
                        <CallToAction to={ctaMainCTAUrl}>{ctaMainCTATitle}</CallToAction>
                        {ctaPricingCTA?.title !== '' && (
                            <CallToAction type="secondary" to={ctaPricingCTA?.url}>
                                {ctaPricingCTA?.title} .
                            </CallToAction>
                        )}
                    </div>
                </div>
                {gatsbyImage && (
                    <div className="md:max-h-[200px] md:max-w-[400px]">
                        <GatsbyImage alt={ctaTitle} image={gatsbyImage} />
                    </div>
                )}
                {svgImage && !gatsbyImage && (
                    <div className="md:max-w-[400px]">
                        <img alt={ctaTitle} src={svgImage} />
                    </div>
                )}
            </div>
        </SectionWrapper>
    )
}
